import RoleGuard from "@/components/common/RoleGuard/RoleGuard.vue";

export default [
  {
    path: "",
    component: RoleGuard,
    props: {
      allowedRoles: ["ADMINISTRATOR"],
      redirectPath: "/",
    },
    children: [
      {
        path: "/Setup/groups",
        name: "GroupConfiguration",
        component: () =>
          import(
            "../pages/main/modernframework/GroupV2/GroupConfigurationV2.vue"
          ),
      },
      {
        path: "/Setup/groups/create",
        name: "GroupCreate",
        component: () =>
          import("../pages/main/modernframework/GroupV2/GroupDetailV2.vue"),
      },
      {
        path: "/Setup/groups/:id/modify",
        name: "GroupModify",
        component: () =>
          import("../pages/main/modernframework/GroupV2/GroupDetailV2.vue"),
      },
    ],
  },
];
