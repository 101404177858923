import httpClient from "../httpClient";
import { request } from "../request";

/**
 * 프로파일 정렬 목록을 가져온다
 * @param {number} projectId - 프로젝트 아이디
 * @returns
 */
export const getProfileOrders = (projectId) =>
  request({
    method: "get",
    uri: `/v1/project/${projectId}/profile/order`,
  });

/**
 * 프로파일 정렬 목록을 등록하거나 수정한다.
 * @typedef {object} ProfileOrder
 * @property {number} project_id - 프로젝트 아이디
 * @property {number} profile_id - 프로젝트 아이디
 * @property {number} member_id - 사용자의 아이디
 * @property {number} order - 정렬 순서
 * @param {{projectId:number, profileOrders: ProfileOrder[]}} param0
 * @returns
 */
export const upsertProfileOrders = ({ projectId, profileOrders }) =>
  request({
    method: "post",
    uri: `/v1/project/${projectId}/profile/order`,
    data: profileOrders,
  });

export const getProfileDetail = ({ projectId, profileId }) =>
  request({
    method: "get",
    uri: `/v1/project/${projectId}/profile/${profileId}`,
  });

export default {
  getProfileWizardStep(projectId, profileId) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}`)
        .then((res) => {
          resolve(res.data.result);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  openProfileWizard(projectId, profileId) {
    // const param = {
    //   wizard_property: "1"
    // }
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}`)
        .then((res) => {
          const param = {
            ...res.data.result,
            wizard_property: "1",
          };
          httpClient
            .put(`/v1/project/${projectId}/profile/${profileId}`, param)
            .then((res) => {
              resolve(res.data.result);
            })
            .catch((err) => {
              reject(err.response);
            });
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  closeProfileWizard(projectId, profileId) {
    // const param = {
    //   wizard_property: "DONE"
    // }
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}`)
        .then((res) => {
          const param = {
            ...res.data.result,
            wizard_property: "",
          };
          httpClient
            .put(`/v1/project/${projectId}/profile/${profileId}`, param)
            .then((res) => {
              resolve(res.data.result);
            })
            .catch((err) => {
              reject(err.response);
            });
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
  setProfileWizardStep(projectId, profileId, step) {
    return new Promise((resolve, reject) => {
      httpClient
        .get(`/v1/project/${projectId}/profile/${profileId}`)
        .then((res) => {
          const param = {
            ...res.data.result,
            wizard_property: String(step),
          };
          httpClient
            .put(`/v1/project/${projectId}/profile/${profileId}`, param)
            .then((res) => {
              resolve(res.data.result);
            })
            .catch((err) => {
              reject(err.response);
            });
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },
};
