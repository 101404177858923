
<v-data-table
  :headers="headers"
  :items="items"
  :hide-default-footer="true"
  :hide-default-header="true"
  class="elevation-1 w-full"
>
  <template v-slot:header="{ props }">
    <tr>
      <th
        v-for="header in props.headers"
        :key="header.text"
        class="header-cell"
      >
        {{ header.text }}
      </th>
    </tr>
  </template>
  <template v-slot:item="{ item }">
    <tr class="row-item">
      <td>{{ item.rdeCount }}</td>
      <td>{{ item.cpu }}</td>
      <td>{{ item.memory }}</td>
      <td>{{ item.disk }}</td>
    </tr>
  </template>
  <template v-slot:no-data>
    <v-alert :value="true" color="error" icon="mdi-alert">
      No data available
    </v-alert>
  </template>
</v-data-table>
