<template>
  <div class="app-detail-rde-popup">
    <div class="header">
      <p>
        <v-icon @click="turnOffFunc"> mdi-arrow-right </v-icon>
        SSH Information - {{ selectedRde?.name }}
      </p>
    </div>
    <div class="data-view-wrapper m-4">
      <div class="flex justify-between">
        <div class="title">Private Key</div>
        <div class="flex flex-row gap-2">
          <button class="mb-1" @click="copyToClipboard(privateKeyStr)">
            <v-icon>mdi-content-copy</v-icon>
          </button>
          <button
            class="mb-1"
            @click="
              downloadAsFile(
                privateKeyStr,
                fileName || selectedRde.ideConfigCrdName + '-ssh-key',
              )
            "
          >
            <v-icon>mdi-download</v-icon>
          </button>
        </div>
      </div>
      <MonacoEditor
        language="yaml"
        :value="privateKeyStr || ''"
        :readOnly="true"
        :miniMap="false"
        :hideDetails="true"
        height="50vh"
        class="editor-wrap"
        lineNumbers="off"
      />
    </div>
    <div class="data-view-wrapper m-4">
      <div class="flex justify-between">
        <div class="title">SSH Config</div>
        <div class="flex flex-row gap-2">
          <button class="mb-1" @click="copyToClipboard(sshConfigStr)">
            <v-icon>mdi-content-copy</v-icon>
          </button>
          <button
            class="mb-1"
            @click="downloadAsFile(sshConfigStr, generateFilename('sshConfig'))"
          >
            <v-icon>mdi-download</v-icon>
          </button>
        </div>
      </div>
      <MonacoEditor
        language="yaml"
        :value="sshConfigStr || ''"
        :readOnly="true"
        :miniMap="false"
        :hideDetails="true"
        height="20vh"
        theme="vs-white"
        class="editor-wrap"
        lineNumbers="off"
      />
    </div>
  </div>
</template>

<script>
import MonacoEditor from "@/components/compositions/MonacoEditor/MonacoEditor.vue";
import { getSshInformation } from "@/service/apis/workspaceApis";
import CommonUIControl from "@/helper/CommonUIControl";
import moment from "moment";

export default {
  props: {
    turnOffFunc: {
      type: Function,
      default() {
        return undefined;
      },
    },
    selectedRde: { type: Object, default: () => {} },
    setExpand: { type: Function, default() {} },
  },
  components: {
    MonacoEditor,
  },
  data: () => {
    return {
      privateKeyStr: "",
      sshConfigStr: "",
      fileName: "",
    };
  },
  watch: {
    selectedRde: {
      handler(newValue) {
        console.log("RDE:", newValue);
        if (newValue?.id) {
          this.handleGetShhInfo(newValue?.id);
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleGetShhInfo(rdeID) {
      CommonUIControl.ShowUIProgress();
      getSshInformation(rdeID)
        .then((res) => {
          const sshInfo = res?.data?.data;
          if (sshInfo) {
            this.privateKeyStr = sshInfo.privateKey;
            this.sshConfigStr = sshInfo.sshConfig;
            this.fileName = this.extractIdentityFile(this.sshConfigStr);
          }
          console.log("API", sshInfo);
        })
        .finally(() => {
          CommonUIControl.HideUIProgress();
        });
    },
    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        CommonUIControl.ShowSuccessToast("Copied to clipboard!");
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    },
    downloadAsFile(content, filename) {
      const blob = new Blob([content], { type: "application/octet-stream" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    },
    generateFilename(type) {
      const formattedDate = moment().format("YYYY-MM-DD HH:mm");
      return `${this.selectedRde.name}-${type}-${formattedDate}`;
    },
    extractIdentityFile(sshConfigStr) {
      const match = sshConfigStr.match(/IdentityFile \.\/([^\s]+)/);
      return match ? match[1] : null;
    },
  },
  mounted() {
    console.log("RDE", this.selectedRde);
  },
};
</script>

<style lang="scss" scoped>
.app-detail-rde-popup {
  padding: 0 !important;
  height: 100%;
  background-color: #fff;
  .v-tabs-bar {
    padding: 0px;
    .v-slide-group__content {
      padding: 15px 0px;
    }
    .v-slide-group__prev {
      display: none;
    }
    .v-slide-group__next {
      display: none;
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    height: 46px;
    padding-left: 5px;
    padding-right: 10px;
    background-color: #fff;
    border-bottom: 1px solid #aeaeae;
    p {
      font-size: 16px;
      font-weight: 600;
      color: #4b4b4b;
      margin: 0;
    }
  }

  .title {
    font-size: 16px !important;
    font-weight: 700;
    letter-spacing: 0.0015em;
    text-align: left;
    color: #343c6a;
  }

  .custom-tab {
    width: 50%;
  }
  .v-window__container {
    padding: 0px !important;
  }

  .log-dashboard2-section {
    padding: 10px 5px 0px 10px;
    overflow: auto;
  }
  .editor-wrap {
    border: 1px solid #cacaca;
    border-radius: 5px;
    padding: 5px;
  }

  .data-view-wrapper {
    margin: 10px !important;
  }
}
</style>
