
<v-snackbar
  :key="`snack-v2-${message}`"
  v-model="showSnackbar"
  :timeout="timeout"
  :absolute="absolute"
  top
  :color="color"
  :left="left"
  :right="right"
  text
  :class="['snackbar-v2-wrapper', contentClass]"
  @input="toggleToast"
>
  <div class="flex justify-between">
    <div class="title-toast">{{ title || "Inform" }}</div>
    <v-icon @click="closeToast">mdi-close</v-icon>
  </div>
  <div>
    <template v-if="message">
      {{ message }}
    </template>
    <component :is="content" v-else />
  </div>
</v-snackbar>
