<template>
  <div class="flex flex-row">
    <span class="px-1"> • </span>
    {{ formattedElapsedTime }}
  </div>
</template>

<script>
export default {
  props: {
    startTime: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentTime: new Date(),
    };
  },
  computed: {
    formattedElapsedTime() {
      if (!this.startTime) return "00:00";

      const start = new Date(this.startTime + "Z");
      const now = this.currentTime;
      const elapsed = new Date(now - start);
      const hours = String(elapsed.getUTCHours()).padStart(2, "0");
      const minutes = String(elapsed.getUTCMinutes()).padStart(2, "0");
      const seconds = String(elapsed.getUTCSeconds()).padStart(2, "0");

      if (hours > 0) {
        return `${hours}:${minutes}:${seconds}`;
      } else {
        return `${minutes}:${seconds}`;
      }
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.currentTime = new Date();
    }, 1000); // Update every second
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
