<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :hide-default-footer="true"
    :hide-default-header="true"
    class="elevation-1 w-full"
  >
    <template v-slot:header="{ props }">
      <tr>
        <th
          v-for="header in props.headers"
          :key="header.text"
          class="header-cell"
        >
          {{ header.text }}
        </th>
      </tr>
    </template>
    <template v-slot:item="{ item }">
      <tr class="row-item">
        <td>{{ item.rdeCount }}</td>
        <td>{{ item.cpu }}</td>
        <td>{{ item.memory }}</td>
        <td>{{ item.disk }}</td>
      </tr>
    </template>
    <template v-slot:no-data>
      <v-alert :value="true" color="error" icon="mdi-alert">
        No data available
      </v-alert>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "RDE Count", value: "rdeCount", sortable: false },
        { text: "CPU", value: "cpu", sortable: false },
        { text: "Memory", value: "memory", sortable: false },
        { text: "Disk", value: "disk", sortable: false },
      ],
      items: [
        {
          rdeCount: 25,
          cpu: "25c",
          memory: "32Gi",
          disk: "100Gi",
        },
        {
          rdeCount: 10,
          cpu: "25c",
          memory: "32Gi",
          disk: "100Gi",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped>
.elevation-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.header-cell {
  background-color: #e4e5eb;
  color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.004em;
  text-align: left;
}

.row-item td {
  font-size: 12px !important;
  font-weight: 400;
  letter-spacing: 0.004em;
  text-align: left;
}
</style>
