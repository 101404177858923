import Vue from "vue";
// import Vuetify from "vuetify/lib";
import Vuetify from "vuetify";
// import "@mdi/font/css/materialdesignicons.css";
// import "../styles/App.scss";
import { Ripple } from "vuetify/lib/directives";

// import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#4b6ced",
        secondary: "#ced2d8",
        accent: "#2A2D45",
        error: "#F23030",
        info: "#39f",
        success: "#2eb85c",
        warning: "#f9b115",
        anchor: "#321fdb",
        disabled: "#e4e5ed",
        "text-primary": "#343C6A",
        "primary-600": "#385ceb",
      },
    },
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: "mdi",
  },
});
