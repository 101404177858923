
<v-app>
  <v-snackbar
    v-model="$store.state.ui.toast.show"
    class="app-g-toast"
    :timeout="$store.state.ui.toast.timeout"
    top
    right
    :color="$store.state.ui.toast.color"
  >
    <v-icon>{{ $store.state.ui.toast.icon }}</v-icon>
    {{ $store.state.ui.toast.message }}
    <v-btn @click="$store.state.ui.toast.show = false">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-snackbar>
  <SnackBarV2 v-bind="$store.state.ui.toastV2" />
  <v-dialog
    v-model="$store.state.ui.dialog.show"
    :persistent="$store.state.ui.dialog.isModal"
    scrollable
    max-width="400px"
    content-class="app-g-dialog-alert"
    @click:outside="didClickDialogOutside"
  >
    <v-card>
      <template v-if="$store.state.ui.dialog.showTitle">
        <v-card-title>
          <span class="headline">{{ $store.state.ui.dialog.title }}</span>
          <v-spacer />
          <v-btn icon color="grey" @click="didCloseModalDialog(false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
      </template>

      <!-- <v-card-text :style="{'padding-top': $store.state.ui.dialog.showTitle?'16px':'24px'}">
        <p>{{ $store.state.ui.dialog.message }}</p>
      </v-card-text> -->

      <v-card-text>
        <v-container class="text-center">
          <v-icon
            v-if="$store.state.ui.dialog.icon != null"
            class="icon"
            :color="$store.state.ui.dialog.icon.color"
          >
            {{ $store.state.ui.dialog.icon.value }}
          </v-icon>
          <div v-if="$store.state.ui.dialog.message" class="desc break-keep">
            {{ $store.state.ui.dialog.message }}
          </div>
        </v-container>
      </v-card-text>

      <v-divider />
      <v-card-actions
        v-if="
          $store.state.ui.dialog.showConfirmButton ||
          $store.state.ui.dialog.showCancelButton
        "
      >
        <v-spacer />
        <Button
          v-if="$store.state.ui.dialog.showCancelButton"
          @click="didCloseModalDialog(false)"
        >
          {{ $store.state.ui.dialog.cancelButtonText || "아니오" }}
        </Button>
        <Button
          v-if="$store.state.ui.dialog.showConfirmButton"
          color="primary"
          fill
          @click="didCloseModalDialog(true)"
        >
          {{ $store.state.ui.dialog.confirmButtonText || "예" }}
        </Button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <DialogContent v-bind="$store.state.ui.dialogV2.content" />
  <DialogConfirm v-bind="$store.state.ui.dialogV2.confirm" />
  <div class="app-area">
    <!-- <SplashView class="splash-view" :is-loading="!loaded" /> -->
    <transition name="fade" mode="out-in">
      <router-view style="z-index: 1" />
    </transition>
  </div>
  <v-overlay :value="$store.state.ui.progress.circular" z-index="1000">
    <v-progress-circular indeterminate size="64" color="primary" />
  </v-overlay>
</v-app>
