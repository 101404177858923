<template>
  <div class="wrap-cards w-full grid grid-cols-1">
    <div class="child1 col-span-1 grid grid-cols-2 gap-2">
      <div class="col-span-1 flex flex-col justify-center pr-1">
        <div :class="['add-on-info', { 'pl-11': item?.instanceType !== null }]">
          {{ item.name }}
        </div>
        <div class="flex flex-row gap-1">
          <div
            v-if="item?.instanceType != null"
            class="flex flex-col justify-center p-1"
          >
            <SvgIcon
              v-if="item?.instanceType == 'node'"
              iconName="eks"
              color="#006AD4"
              size="lg"
            />
            <SvgIcon v-else iconName="fargate" color="#006AD4" size="lg" />
          </div>

          <span class="workspace-name pt-1"
            >{{ item.displayName || item.name }}
          </span>

          <Tooltip
            v-if="item?.description"
            :text="item?.description"
            position="right"
            targetSelf
            class="pr-1 flex flex-col justify-center"
          >
            <v-icon size="medium"> mdi-information-outline </v-icon>
          </Tooltip>
          <div class="flex flex-col justify-center">
            <ItemStatus
              :status="podStatus"
              :item="item"
              :tooltip="tooltipMsg"
              :viewRDEState="onViewRDEState"
              :rdeColor="rdeColor"
            >
              <template
                v-if="
                  startTime &&
                  podStatus != POD_STATUS.failed &&
                  podStatus != POD_STATUS.stop &&
                  podStatus != POD_STATUS.shutdown
                "
                v-slot:elapse-time
              >
                <ElapsedRunTime :startTime="startTime" />
              </template>
            </ItemStatus>
          </div>
        </div>
      </div>
      <div class="col-span-1 flex flex-col justify-center">
        <div class="add-on-info padding mt-1 flex flex-row">
          <div>Workspace:</div>
          <div class="add-on-detail pl-1 flex flex-row">
            <span>{{ item?.workspace?.displayName }}</span>
            <Tooltip
              v-if="item?.workspace?.name"
              :text="`
              System name: <b>${item?.workspace?.name}</b> <br />
              Display name: <b>${item?.workspace?.displayName}</b>
              `"
              position="right"
              targetSelf
              class="pl-1 flex flex-col justify-center"
            >
              <v-icon size="medium"> mdi-information-outline </v-icon>
            </Tooltip>
          </div>
        </div>
        <div class="add-on-info padding">
          Namespace: <span>{{ item?.namespace }}</span>
        </div>
        <div class="add-on-info padding">
          User: <span>{{ item?.userName }}</span>
        </div>
      </div>
    </div>
    <div class="child2 col-span-1 grid grid-cols-2 gap-2">
      <div class="wp-info grid grid-cols-3 gap-3">
        <div class="col-span-1 flex flex-col justify-center">
          <div class="flex flex-row gap-1">
            <SvgIcon iconName="cpu" size="sm" color="#87888C" />
            <label>CPU</label>
          </div>
          <div class="resource-box">
            <span
              class="usage-percentage"
              :style="$helper.getUsageStyle(item.currentUsage?.cpu, item?.cpu)"
            ></span>
            <label class="content">
              {{
                $helper.viewCpuData2(item?.currentUsage?.cpu || 0, item?.cpu)
              }}
            </label>
          </div>
        </div>
        <div class="col-span-1 flex flex-col justify-center">
          <div class="flex flex-row gap-1">
            <SvgIcon iconName="memory" size="sm" color="#87888C" />
            <label>Memory</label>
          </div>
          <div class="resource-box">
            <span
              class="usage-percentage"
              :style="
                $helper.getUsageStyle(item.currentUsage?.memory, item?.memory)
              "
            ></span>
            <label class="content">
              {{
                $helper.viewMemoryData2(
                  item?.currentUsage?.memory || 0,
                  item?.memory,
                )
              }}
            </label>
          </div>
        </div>
        <div class="col-span-1 flex flex-col justify-center">
          <div class="flex flex-row gap-1">
            <SvgIcon iconName="disk" size="sm" color="#87888C" />
            <label>Disk</label>
          </div>
          <div class="resource-box">
            <span
              class="usage-percentage"
              :style="
                $helper.getUsageStyle(
                  this.$helper.byteToGibibyte(item.currentUsage?.disk, 0),
                  item?.disk,
                )
              "
            ></span>
            <label class="content">
              {{
                `${$helper.viewDiskData(item.currentUsage?.disk, item?.disk)}`
              }}
            </label>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col justify-center">
        <div class="actions flex flex-row gap-2 p-2 w-full flex-wrap">
          <div class="icon-resource">
            <v-icon
              :disabled="
                podStatus == POD_STATUS.shutdown ||
                podStatus == POD_STATUS.stop ||
                podStatus == POD_STATUS.failed ||
                podStatus == POD_STATUS.resourcing ||
                podStatus == POD_STATUS.terminating
              "
              title="view resource detail"
              @click="() => viewResourceRDE(item)"
            >
              mdi-note-text-outline
            </v-icon>
          </div>
          <ButtonStatus
            :available="item.serviceTypes.includes('vscode')"
            :disabled="podStatus !== POD_STATUS.running"
            btType="vscode"
            :status="listServiceStatus.vscodeserver"
            :to="`${buildURL(item)}/vscode`"
            :podStatus="podStatus"
          />
          <ButtonStatus
            :available="item.serviceTypes.includes('webssh')"
            :disabled="podStatus !== POD_STATUS.running"
            btType="ssh"
            :status="listServiceStatus.sshserver"
            :to="`${buildURL(item)}/ssh`"
            :podStatus="podStatus"
          />
          <ButtonStatus
            :available="item.serviceTypes.includes('notebook')"
            :disabled="podStatus !== POD_STATUS.running"
            btType="jupyter"
            :status="listServiceStatus.jupyter"
            :to="`${buildURL(item)}/notebook`"
            :podStatus="podStatus"
          />
          <div class="menu-btn flex flex-col justify-center">
            <div class="text-right flex flex-row">
              <IconButton
                class="border-none"
                size="md"
                iconName="mdi-cached"
                color="#87888C"
                tooltip="Update Status"
                @click="() => reloadRdeStatus(item.id)"
              ></IconButton>
              <v-menu offset-y transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <IconButton
                    class="border-none"
                    size="md"
                    iconName="mdi-dots-horizontal"
                    color="#87888C"
                    tooltip="Actions"
                    v-on="on"
                  ></IconButton>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(menuItem, i) in Menus"
                    :key="i"
                    class="list-rde-menu-item py-1"
                    :disabled="menuItem.disabled"
                    @click="menuItem.action()"
                  >
                    <v-list-item-title>
                      <div class="flex flex-row gap-1 items-center">
                        <v-icon
                          v-if="menuItem.icon"
                          :color="
                            menuItem.disabled ? '#00000060' : menuItem.color
                          "
                        >
                          {{ menuItem.icon }}
                        </v-icon>
                        <ImageIcon
                          v-if="menuItem.svgIcon"
                          :iconName="menuItem.svgIcon"
                        />
                        {{ menuItem.title }}
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemStatus from "../../../common/ItemStatus.vue";
import { RequestMixin } from "@/mixins/RequestMixin";
import { getRdeStatus } from "@/service/apis/workspaceApis";
import { UNIT_LABELS, POD_STATUS } from "@/service/constants";
import { updateRdeStatus, forceRestartRde } from "@/service/apis/workspaceApis";
import ElapsedRunTime from "./ElapsedRunTime.vue";
import moment from "moment";

const LIST_SERVICE = ["sshserver", "vscodeserver", "jupyter"];
const RdeStatus = {
  waiting: "waiting",
  running: "running",
  terminated: "terminated",
  restart: "restart",
};

const RELOAD_TIMEOUT = 10000;
const SHORT_RELOAD_TIMEOUT = 4000;

export default {
  props: {
    item: {
      type: [Array, Object],
    },
    namespaces: { type: String },
    workspaceName: { type: String },
    createdBy: { type: String },
    viewResourceRDE: { type: Function, default: () => undefined },
    viewStateRDE: { type: Function, default: () => undefined },
    showSshInfo: { type: Function, default: () => undefined },
  },
  components: { ItemStatus, ElapsedRunTime },
  mixins: [RequestMixin],
  data() {
    return {
      podStatus: POD_STATUS.loading,
      listServiceStatus: {
        sshserver: RdeStatus.waiting,
        vscodeserver: RdeStatus.waiting,
        jupyter: RdeStatus.waiting,
      },
      tooltipMsg: "Loading...",
      UNIT_LABELS: UNIT_LABELS,
      POD_STATUS: POD_STATUS,
      isUpdating: false,
      menuItems: [
        {
          svgIcon: "grafana",
          title: "Dashboard",
          action: this.onMenuGrafana,
        },
        {
          icon: "mdi-laptop-mac",
          color: "#22659D",
          title: "Status",
          action: this.onViewRDEState,
          notShowOn: [POD_STATUS.shutdown, POD_STATUS.stop],
        },
        {
          icon: "mdi-information-outline",
          color: "#22659D",
          title: "SSH Information",
          action: this.onShowSshInfo,
          showWhen: this.item.serviceTypes.includes("webssh"),
        },
        {
          icon: "mdi-stop-circle-outline",
          color: "#E55353",
          title: "Stop",
          action: this.onStop,
          notShowOn: [POD_STATUS.stop, POD_STATUS.shutdown],
        },
        {
          icon: "mdi-power",
          color: "#F9B115",
          title: "Shutdown",
          action: this.onShutdown,
          notShowOn: [POD_STATUS.shutdown, POD_STATUS.stop],
        },
        {
          icon: "mdi-play-circle-outline",
          color: "#0095AC",
          title: "Resume",
          action: this.onResume,
          showOn: [POD_STATUS.stop],
        },
        {
          icon: "mdi-restart",
          color: "#2EB85C",
          title: "Restart",
          action: this.onRestart,
          showOn: [POD_STATUS.shutdown],
        },
        {
          icon: "mdi-restart",
          color: "#FD7E14",
          title: "Force Restart",
          action: this.onForceRestart,
          notShowOn: [POD_STATUS.shutdown, POD_STATUS.stop],
        },
        {
          icon: "mdi-pencil",
          color: "#321FDB",
          title: "Modification",
          action: this.onEditRDE,
          //showOn: ["owner"],
          notShowOn: [POD_STATUS.shutdown],
        },
        {
          icon: "mdi-trash-can-outline",
          color: "#646569",
          title: "Delete",
          action: this.onDelete,
          //showOn: ["owner"],
        },
      ],
      Menus: [],
      startTime: null,
      rdeColor: null,
    };
  },
  watch: {
    podStatus(status) {
      if (status) {
        this.Menus = this.filteredMenus(
          status,
          this.item?.workspace?.isOwner,
          this.item,
        );
      }
    },
  },
  methods: {
    async fetchRdeStatus(force = false) {
      if (this.isUpdating && !force) {
        return false;
      }
      if (this.item) {
        const params = {
          id: this.item.id,
          name: this.item.ideConfigCrdName,
          namespace: this.item.namespace,
        };
        return new Promise((resolve, reject) => {
          getRdeStatus(params).then(
            (res) => {
              this.podStatus = res.data?.status;
              this.rdeColor = res.data?.color || null;
              this.startTime = res.data?.startTime;
              const rdeStatus = res.data.rdeStatus;
              if (rdeStatus) {
                this.tooltipMsg = "";
                for (const i in LIST_SERVICE) {
                  const serviceName = LIST_SERVICE[i];
                  const serviceStatus = rdeStatus.find((item) => {
                    return item.name === serviceName;
                  });

                  if (serviceStatus) {
                    this.listServiceStatus[serviceName] = serviceStatus.status;
                    this.tooltipMsg += this.buildTooltipText(serviceStatus);
                  } else {
                    this.listServiceStatus[serviceName] = RdeStatus.waiting;
                    this.tooltipMsg = res.data.message || "";
                  }
                }
              } else {
                this.tooltipMsg = res?.data?.message || "Loading...";
              }
              resolve(res.data.status);
            },
            (err) => {
              // In case getting rde status error
              this.podStatus = POD_STATUS.loading;
              this.$emit("getting-rde-status-error", params);
              reject(err);
            },
          );
        });
      }
    },
    async handleStatusItem(item, status, callback = null) {
      await this.mutation(
        () =>
          updateRdeStatus({
            rdeId: item.id,
            status,
          }),
        {
          onSuccess: () => {
            if (callback) {
              callback();
            }
          },
          onError: (err) => {
            if (callback) {
              callback();
            }
            console.log(err);
          },
        },
      );
    },
    async handleForceRestart(item) {
      await this.mutation(
        () =>
          forceRestartRde({
            rdeId: item.id,
          }),
        {
          onSuccess: () => {},
          onError: (err) => {
            console.log(err);
          },
        },
      );
    },
    buildTooltipText(container) {
      let tooltipStr = "";
      if (container) {
        if (container.status != RdeStatus.running) {
          tooltipStr = `- ${container.name || ""}: (${
            container.reason || ""
          } | ${container.message || ""})`;
        }
      }
      return tooltipStr ? `${tooltipStr} \n\r` : "";
    },
    buildURL(item) {
      const strUlr = `/remote-development-env/${item.userName}/${
        item.workspace?.name
      }/${item.name}/${item?.namespace}/${item.domainInfo.domainURL
        .replace("https://", "")
        .replace("http://", "")}`;
      return strUlr;
    },
    async reloadRdeStatus() {
      await this.fetchRdeStatus();
    },
    forceSetStatus(status) {
      this.podStatus = status;
      this.keepReloadStatus();
    },
    keepReloadStatus() {
      this.isUpdating = true;
      this.attemptReload(3);
    },
    attemptReload(attemptsLeft) {
      if (attemptsLeft > 0) {
        setTimeout(() => {
          this.fetchRdeStatus(true);
          this.attemptReload(attemptsLeft - 1);
        }, SHORT_RELOAD_TIMEOUT);
      } else {
        this.isUpdating = false;
      }
    },
    filteredMenus(status, isOwner, itemData = null) {
      return this.menuItems.filter((item) => {
        if (item.notShowOn) {
          return !item.notShowOn?.includes(status);
        }

        if (item.showOn) {
          if (isOwner)
            return (
              item.showOn?.includes(status) || item.showOn?.includes("owner")
            );
          else
            return (
              !item.showOn?.includes("owner") && item.showOn?.includes(status)
            );
        }

        if (item.title === "Dashboard" && !itemData?.dashboardUrl) {
          return false;
        }
        if (item.showWhen != null) {
          return item.showWhen;
        }
        return true;
      });
    },
    // RDE Actions
    onMenuGrafana() {
      window.open(this.item?.dashboardUrl, "_blank");
    },
    onEditRDE() {
      const rdeId = this.item.id;
      if (rdeId) {
        const workspaceId =
          this.item?.workspace?.id || this.$route.params.workspaceId;
        this.$router.push(`/workspace/${workspaceId}/rde/${rdeId}/modify`);
      }
    },
    onViewRDEState(showLogs = false) {
      if (this.item) {
        this.viewStateRDE(this.item, showLogs);
      }
    },
    handleUpdateStatus(status) {
      // Right after updating status, keep reloading status every 5s (3 times)
      this.tooltipMsg = "";
      this.podStatus = status;
      this.setStartTimeToZero();
      this.handleStatusItem(this.item, status, this.keepReloadStatus);
    },
    onStop() {
      this.handleUpdateStatus(POD_STATUS.stop);
    },
    onShutdown() {
      this.handleUpdateStatus(POD_STATUS.shutdown);
    },
    onResume() {
      this.handleUpdateStatus(POD_STATUS.resume);
    },
    onRestart() {
      this.handleUpdateStatus(POD_STATUS.restart);
    },
    onForceRestart() {
      this.setStartTimeToZero();
      this.podStatus = POD_STATUS.restarting;
      this.handleForceRestart(this.item);
      this.keepReloadStatus();
    },
    onDelete() {
      this.$emit("click:remove", this.item);
    },
    checkModification() {
      const rdeId = this.$route.params.updatedId;
      if (rdeId && rdeId === this.item.id) {
        this.podStatus = POD_STATUS.updating;

        this.setStartTimeToZero();

        setTimeout(() => {
          this.keepReloadStatus();
        }, 1000);

        //remove the updatedId from the route after checking modification
        const workspaceId =
          this.item?.workspace?.id || this.$route.params.workspaceId;
        this.$router.replace(`/newwp/list-rde/${workspaceId}`);
      }
    },
    setStartTimeToZero() {
      this.startTime = moment.utc().format("YYYY-MM-DDTHH:mm:ss");
    },
    onShowSshInfo() {
      this.showSshInfo(this.item);
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.fetchRdeStatus();
    }, RELOAD_TIMEOUT);
  },
  async beforeMount() {
    // Getting RDE status at the first load, if the status is Pending -> keep reloading status every 5s (3 times)
    const status = await this.fetchRdeStatus();
    if (status && status == POD_STATUS.pending) {
      this.keepReloadStatus();
    }

    // Check if the RDE is being modified -> keep reloading status every 5s (3 times)
    if (
      status &&
      status !== POD_STATUS.stop &&
      status !== POD_STATUS.shutdown
    ) {
      this.checkModification();
    }
  },
  beforeDestroy() {
    if (this.interval) {
      clearTimeout(this.interval);
    }
  },
};
</script>
<style type="scss" scoped>
.icon-resource {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-right: 1px solid #dadce0;
  padding-right: 20px;
  margin-right: 15px; */
  .v-icon::after {
    display: none;
  }

  button {
    border: 1px solid #dadce0;
    border-radius: 4px;
    padding: 5px 8px;
    color: black;
  }
}

.add-on-info {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: #646569;
}

.add-on-info > span,
div.add-on-detail > span {
  font-weight: bold;
}

.padding {
  padding: 2px 0;
}

.resource-box {
  width: 95%;
  height: 20px;
  background-color: #e4e5eb;
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
}
.usage-percentage {
  content: " ";
  height: 100%;
  background-color: aquamarine;
  position: absolute;
  top: 0;
  left: 0;
}
.content {
  margin-left: 3px;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-desc {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  letter-spacing: 0.0015em;
  text-align: left;
  color: #87888c;
}

@media only screen and (min-width: 1180px) {
  .wrap-cards {
    @apply grid-cols-1;
  }

  .child1 {
    @apply col-span-1;
  }

  .child2 {
    @apply col-span-1;
  }

  .icon-resource {
    padding-right: 0px;
    margin-right: 0px;
    border-right: none;
  }
  .actions {
    @apply justify-start;
  }
}

@media only screen and (min-width: 1400px) {
  .wrap-cards {
    @apply grid-cols-5;
  }

  .child1 {
    @apply col-span-2;
  }

  .child2 {
    @apply col-span-3;
  }

  .icon-resource {
    padding-right: 20px;
    margin-right: 15px;
    border-right: 1px solid #dadce0;
  }

  .actions {
    @apply justify-end;
  }
}
</style>
