import ProjectAuthProvider from "@/components/common/ProjectAuthProvider/ProjectAuthProvider";
import ProjectGuard from "@/components/common/ProjectGuard/ProjectGuard";
import RoleGuard from "@/components/common/RoleGuard/RoleGuard.vue";

export default [
  {
    path: "Setup",
    component: RoleGuard,
    props: {
      allowedRoles: ["ADMINISTRATOR"],
      redirectPath: "/",
    },
    children: [
      {
        path: "PipelineTemplateList",
        name: "PipelineTemplateList",
        component: () =>
          import(
            "../pages/main/modernframework/Pipeline/PipelineTemplateList.vue"
          ),
      },
    ],
  },
  {
    path: "/projects",
    component: ProjectAuthProvider,
    children: [
      {
        path: "",
        component: ProjectGuard,
        children: [
          {
            path: ":projectId/profiles/:profileId/pipelines/:pipelineId",
            name: "PipelineDetail",
            component: () =>
              import(
                "../pages/main/modernframework/Pipeline/PipelineDetail.vue"
              ),
          },
          {
            path: ":projectId/profiles/:profileId/microservices/:microserviceId/Pipeline/:pipelineId",
            name: "PipelineDetailMID", //마이크로서비스 ID 만으로 파이프라인 가져오는 구현이 반영됨
            component: () =>
              import(
                "../pages/main/modernframework/Pipeline/PipelineDetail.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "ProjectDetail/:projectId/profiles/:profileId/pipelineCode/:filename",
    name: "ProjectCodeTemplateFileDetail", //마이크로서비스 ID 만으로 파이프라인 가져오는 구현이 반영됨
    component: () =>
      import(
        "../pages/main/modernframework/ProfileV2/ProfileDetailV2/PipelineSetupV2/PipelineCodeV2/PipelineCodeV2.vue"
      ),
  },
  {
    path: "CodeTemplateDetail/:templateId",
    name: "CodeTemplateDetail",
    component: () =>
      import("../pages/main/modernframework/Pipeline/CodeTemplateDetail.vue"),
    meta: {
      type: "detail",
    },
  },
  {
    path: "CodeTemplateDetail/project/:projectId",
    name: "ProjectCodeTemplateDetail",
    component: () =>
      import("../pages/main/modernframework/Pipeline/CodeTemplateDetail.vue"),
    meta: {
      type: "detail",
    },
  },
  {
    path: "CodeTemplateCreate",
    name: "CodeTemplateCreate",
    component: () =>
      import("../pages/main/modernframework/Pipeline/CodeTemplateDetail.vue"),
    meta: {
      type: "create",
    },
  },
  {
    path: "projects/:projectId/profiles/:profileId/microservices/:microserviceId/Pipeline/:pipelineId/run/:commitrunId",
    name: "PipelineRunResult",
    component: () =>
      import("../pages/main/modernframework/Pipeline/PipelineRunResult.vue"),
    meta: {
      type: "detail",
    },
  },
];
