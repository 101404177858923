
<div class="w-full">
  <h3 class="table-title pb-2">RDE Usage</h3>
  <v-data-table
    :headers="headers"
    :items="items"
    :hide-default-header="true"
    class="elevation-1 rde-usage-table w-full"
  >
    <template v-slot:header="{ props }">
      <tr>
        <th
          v-for="header in props.headers"
          :key="header.text"
          class="header-cell"
        >
          {{ header.text }}
        </th>
      </tr>
    </template>
    <template v-slot:item="{ item }">
      <tr>
        <td>{{ item.rdeName }}</td>
        <td>{{ item.workspaceName }}</td>
        <td>{{ item.instanceType }}</td>
        <td>{{ item.cpu }}</td>
        <td>{{ item.memory }}</td>
        <td>{{ item.disk }}</td>
        <td>{{ item.diskType }}</td>
        <td>{{ formatDate(item.createdDate) }}</td>
        <td>{{ item.used }}</td>
        <td>{{ item.billing }}</td>
        <td>{{ item.creator }}</td>
      </tr>
    </template>
    <template v-slot:no-data>
      <v-alert :value="true" color="error" icon="mdi-alert">
        No data available
      </v-alert>
    </template>
  </v-data-table>
</div>
