import { deepCopy } from "@/service/topologyHelper";
import { UNIT_LABELS } from "@/service/constants";
import _ from "lodash";
import CommonUIControl from "@/helper/CommonUIControl";

export default {
  install(Vue) {
    Vue.prototype.$helper = {
      deepCopy,
      hex2rgba,
      byteToMebibyte,
      coreToMilicore,
      roundNumber,
      byteToGibibyte,
      viewMemoryData,
      viewCpuData,
      milicoreToCore,
      mebibyteToGibibyte,
      getUsageStyle,
      viewMemoryData2,
      viewCpuData2,
      viewDiskData,
      confirmDelete,
    };
  },
};

if (window) {
  /** 기존 사용하던 `require`의 폴리필 */
  window.require = (path) => {
    const replacePath = alias
      .filter(([reg]) => reg.test(path))
      .reduce((acc, [reg, replace]) => acc.replace(reg, replace), path);

    return replacePath;
  };
}

const alias = [
  [/^@\//, new URL("../../src", import.meta.url).href + "/"],
  [
    /^~\//,
    new URL("../../src/pages/main/modernframework", import.meta.url).href + "/",
  ],
];

const STEP = 1024;

const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const byteToMebibyte = (bytes, toFixed = 1) => {
  return roundNumber(bytes / (STEP * STEP), toFixed);
};

const coreToMilicore = (cores, toFixed = 0) => {
  return roundNumber(cores * 1000, toFixed);
};

const milicoreToCore = (milicore, toFixed = 1) => {
  return roundNumber(milicore / 1000, toFixed);
};

const byteToGibibyte = (bytes, toFixed = 1) => {
  return roundNumber(bytes / (STEP * STEP * STEP), toFixed);
};

const mebibyteToGibibyte = (mebibytes, toFixed = 1) => {
  // 1 Mebibyte (MiB) = 1 / 1024 Gibibyte (GiB)
  return roundNumber(mebibytes / STEP, toFixed);
};

const roundNumber = (number, digit = 2) => Number(number.toFixed(digit));

const viewCpuData = (usageData, limitData, noUnit = false) => {
  if (!_.isNull(usageData)) {
    if (usageData >= 0 && usageData < 1000) {
      return `${usageData} / ${limitData} ${
        !noUnit ? UNIT_LABELS.MiliCores : ""
      }`;
    } else {
      const usageDataInCore = roundNumber(usageData / 1000, 0);
      const limitDataInCore = roundNumber(limitData / 1000, 0);
      return `${usageDataInCore} / ${limitDataInCore} ${
        !noUnit ? UNIT_LABELS.Core : ""
      }`;
    }
  } else {
    return `${limitData || 0} ${UNIT_LABELS.MiliCores}`;
  }
};
const viewMemoryData = (usageData, limitData) => {
  if (!_.isNull(usageData)) {
    if (usageData >= 0 && usageData < 1024) {
      return `${usageData} / ${limitData} ${UNIT_LABELS.MebiByte}`;
    } else {
      const usageDataInGiB = roundNumber(usageData / 1024, 0);
      const limitDataInGiB = roundNumber(limitData / 1024, 0);
      return `${usageDataInGiB} / ${limitDataInGiB} ${UNIT_LABELS.GibiByte}`;
    }
  } else {
    return `${limitData || 0} ${UNIT_LABELS.MebiByte}`;
  }
};

const viewCpuData2 = (usageData, limitData, objectResult = false) => {
  if (
    _.isNull(usageData) ||
    _.isUndefined(usageData) ||
    _.isNull(limitData) ||
    _.isUndefined(limitData)
  ) {
    return "N/A";
  }

  // Determine the appropriate unit for limitData
  let limitDataFormatted;
  let limitUnit;

  if (limitData >= 1000) {
    limitDataFormatted = roundNumber(limitData / 1000, 1);
    limitUnit = UNIT_LABELS.Core;
  } else {
    limitDataFormatted = limitData;
    limitUnit = UNIT_LABELS.MiliCores;
  }
  if (!_.isNull(usageData) && !_.isUndefined(usageData)) {
    // Determine the appropriate unit for usageData
    let usageDataFormatted;
    let usageUnit;

    if (usageData >= 1000) {
      usageDataFormatted = roundNumber(usageData / 1000, 1);
      usageUnit = UNIT_LABELS.Core;
    } else {
      usageDataFormatted = roundNumber(usageData, 1);
      usageUnit = UNIT_LABELS.MiliCores;
    }

    if (usageUnit == limitUnit) {
      usageUnit = "";
    }

    if (objectResult) {
      return {
        usageData: usageDataFormatted,
        usageUnit: usageUnit,
        limitData: limitDataFormatted,
        limitUnit: limitUnit,
        percent: calPercent(usageData, limitData),
      };
    }

    // Format the output
    return `${usageDataFormatted} ${usageUnit} / ${limitDataFormatted} ${limitUnit}`;
  } else {
    return `${limitDataFormatted} ${limitUnit}`;
  }
};

const viewMemoryData2 = (usageData, limitData, objectResult = false) => {
  if (
    _.isNull(usageData) ||
    _.isUndefined(usageData) ||
    _.isNull(limitData) ||
    _.isUndefined(limitData)
  ) {
    return "N/A";
  }
  // Conversion factors
  const KIBI = 1024;
  const MEBI = KIBI * 1024;
  const GIBI = MEBI * 1024;

  usageData = usageData * MEBI;

  let limitDataFormatted;
  let limitUnit;

  if (limitData >= 1024) {
    limitDataFormatted = roundNumber(limitData / 1024, 1);
    limitUnit = UNIT_LABELS.GibiByte;
  } else {
    limitDataFormatted = limitData;
    limitUnit = UNIT_LABELS.MebiByte;
  }

  // Determine the appropriate unit for usageData
  let usageDataFormatted;
  let usageUnit;

  if (usageData >= GIBI) {
    usageDataFormatted = usageData / GIBI;
    usageUnit = "";
  } else if (usageData >= MEBI) {
    usageDataFormatted = usageData / MEBI;
    usageUnit = UNIT_LABELS.MebiByte;
  } else if (usageData >= KIBI) {
    usageDataFormatted = usageData / KIBI;
    usageUnit = UNIT_LABELS.KibiByte;
  } else {
    usageDataFormatted = usageData;
    usageUnit = UNIT_LABELS.Byte;
  }
  if (usageUnit == limitUnit || usageDataFormatted == 0) {
    usageUnit = "";
  }

  if (objectResult) {
    return {
      usageData: roundNumber(usageDataFormatted, 1),
      usageUnit: usageUnit,
      limitData: roundNumber(limitDataFormatted, 1),
      limitUnit: limitUnit,
      percent: calPercent(usageData / MEBI, limitData),
    };
  }

  // Format the output
  return `${roundNumber(
    usageDataFormatted,
    1,
  )} ${usageUnit} / ${limitDataFormatted} ${limitUnit}`;
};

const viewDiskData = (usageData, limitData, objectResult = false) => {
  const byteToMebiByte = (bytes) => bytes / (1024 * 1024);
  const byteToGibiByte = (bytes) => bytes / (1024 * 1024 * 1024);

  // Check if usageData is null
  let usageDataFormatted;
  let usageUnit;

  if (_.isNull(usageData) || _.isUndefined(usageData)) {
    usageDataFormatted = "N/A";
    usageUnit = "";
  } else if (usageData >= 1024 * 1024 * 1024) {
    usageDataFormatted = roundNumber(byteToGibiByte(usageData), 0);
    usageUnit = UNIT_LABELS.GibiByte;
  } else {
    usageDataFormatted = roundNumber(byteToMebiByte(usageData), 0);
    usageUnit = UNIT_LABELS.MebiByte;
  }

  // Convert limitData from GibiBytes to MebiBytes
  const limitDataInMebiBytes = limitData * 1024;
  let limitDataFormatted;
  let limitUnit;

  if (limitDataInMebiBytes >= 1024) {
    limitDataFormatted = roundNumber(limitDataInMebiBytes / 1024, 0);
    limitUnit = UNIT_LABELS.GibiByte;
  } else {
    limitDataFormatted = roundNumber(limitDataInMebiBytes, 0);
    limitUnit = UNIT_LABELS.MebiByte;
  }

  if (usageUnit == limitUnit) {
    usageUnit = "";
  }

  if (objectResult) {
    return {
      usageData: usageDataFormatted,
      usageUnit: usageUnit,
      limitData: limitDataFormatted,
      limitUnit: limitUnit,
      percent: calPercent(byteToGibiByte(usageData), limitData),
    };
  }

  // Format the output
  return `${usageDataFormatted} ${usageUnit} / ${limitDataFormatted} ${limitUnit}`;
};

const calPercent = (use, limit) => {
  if (use && limit) {
    // Simplified validation and calculation
    use = parseFloat(use);
    limit = parseFloat(limit);

    if (isNaN(use) || isNaN(limit) || limit === 0) return 0;

    const percent = (use / limit) * 100;
    return percent > 100
      ? 100
      : percent <= 9
      ? roundNumber(percent, 1)
      : Math.round(percent);
  }
  return 0;
};

const getUsageStyle = (currentUsage, total, isRde = false) => {
  if (!currentUsage || !total) {
    return {};
  }
  const width = dynamicWidth(currentUsage, total) + "%";
  const background = isRde ? "#87888C" : dynamicBackGround(currentUsage, total);
  return {
    width: width,
    background: background,
  };
};

const dynamicWidth = (currentUsage, total) => {
  if (total > 0) {
    const percent = currentUsage / total;

    return (percent <= 1 ? percent : 1) * 100;
  }
  return 0;
};

const dynamicBackGround = (usage, limit) => {
  const usagePercentage = (usage / limit) * 100;
  // Example: Change background color based on usage percentage
  let backgroundColor = "#2EB85C"; // Default background
  if (usagePercentage > 80) {
    backgroundColor = "#E55353";
  } else if (usagePercentage > 60) {
    backgroundColor = "#F9B115";
  }
  return backgroundColor;
};

const confirmDelete = (title, message, confirmAction) => {
  CommonUIControl.confirm({
    title: title,
    message: message,
    onConfirm: async () => {
      await confirmAction();
    },
  });
};
