import Vue from "vue";
import Vuex from "vuex";
import { isEmpty } from "lodash";
import topology from "./topology";
import dialog from "./dialog";
import user from "./user";
import projectStore from "./projectStore";
import settingStore from "./settingStore";
import workspace from "./workspace";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    topology,
    dialog,
    user,
    project: projectStore,
    settings: settingStore,
    workspace: workspace,
  },
  state: {
    username: "",
    authToken: null,
    accountInfo: null,
    counter: 0,
    projects: null, // 전체 프로젝트 리스트
    shortSideNav: false, // true일 경우 snb의 영역이 줄어든다
    ui: {
      toast: {
        show: false,
        message: "",
        color: "primary",
        icon: "",
        timeout: 6000,
      },
      toastV2: {
        show: false,
        message: "",
        color: "",
        type: "info",
        content: null,
        title: "",
      },
      dialog: {
        show: false,
        title: "",
        message: "",
        confirmButtonText: "",
        cancelButtonText: "",
        showTitle: false,
        showConfirmButton: false,
        showCancelButton: false,
        didCloseDialog: null,
        isModal: false,
        icon: null,
      },
      dialogV2: {
        confirm: {
          show: false,
          title: "",
          maxWidth: "400px",
          message: "",
          content: "",
          yesText: "Yes",
          cancelText: "Cancel",
          hanldeClickYes: null,
        },
        content: {
          show: false,
          title: "",
          maxWidth: "700px",
          content: "",
          actions: null,
          contentProps: null,
        },
      },
      progress: {
        circular: false,
        appBarLoading: false,
      },
    },
  },
  getters: {
    isMobileBrowser: () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent,
      );
    },
    authToken: (state) => {
      if (isEmpty(state.authToken)) {
        state.authToken = localStorage.getItem(
          process.env.VUE_APP_AUTH_TOKEN_HEADER_KEY,
        );
      }
      return state.authToken;
    },
  },
  mutations: {
    SET_USERNAME(state, username) {
      state.username = username;
      if (isEmpty(username)) {
        localStorage.removeItem(process.env.VUE_APP_USERNAME);
      } else {
        localStorage.setItem(process.env.VUE_APP_USERNAME, username);
      }
    },
    SET_ACCOUNT_INFO(state, info) {
      state.accountInfo = info;
    },
    SET_GLOBAL_UI_PROGRESS(state, value) {
      state.ui.progress.circular = value;
    },
    SET_APP_BAR_LOADING(state, value) {
      state.ui.progress.appBarLoading = value;
    },
    SHOW_TOAST(state, info) {
      state.ui.toast.message = info.message;
      state.ui.toast.timeout = info.timeout ? info.timeout : 1000;
      state.ui.toast.color = info.color;
      state.ui.toast.icon = info.icon;
      state.ui.toast.show = true;
    },
    SHOW_TOAST_V2(state, data) {
      state.ui.toastV2 = data;
      state.ui.toastV2.show = true;
    },
    HIDE_TOAST_V2(state) {
      state.ui.toastV2 = {
        show: false,
        message: "",
        color: "",
        type: "info",
        content: null,
      };
    },
    SHOW_MODAL_DIALOG(state, info) {
      state.ui.dialog.title = info.title;
      state.ui.dialog.showTitle = !isEmpty(info.title);
      state.ui.dialog.message = info.message;
      state.ui.dialog.confirmButtonText = isEmpty(info.confirmButtonText)
        ? "Ok"
        : info.confirmButtonText;
      state.ui.dialog.showConfirmButton = !isEmpty(info.confirmButtonText);
      state.ui.dialog.cancelButtonText = isEmpty(info.cancelButtonText)
        ? "Cancel"
        : info.cancelButtonText;
      state.ui.dialog.showCancelButton = !isEmpty(info.cancelButtonText);
      state.ui.dialog.didCloseDialog = info.didCloseDialog;
      state.ui.dialog.show = true;
      state.ui.dialog.isModal = info.isModal;
      state.ui.dialog.icon = info.icon;
    },
    DISMISS_MODAL_DIALOG(state) {
      state.ui.dialog.title = "";
      state.ui.dialog.message = "";
      state.ui.dialog.confirmButtonText = "";
      state.ui.dialog.cancelButtonText = "";
      state.ui.dialog.didCloseDialog = null;
      state.ui.dialog.showTitle = false;
      state.ui.dialog.showCancelButton = false;
      state.ui.dialog.showConfirmButton = false;
      state.ui.dialog.show = false;
      state.ui.dialog.isModal = false;
      state.ui.dialog.icon = null;
    },
    SHOW_MODAL_DIALOG_CONFIRM_V2(state, data) {
      state.ui.dialogV2.confirm = data;
      state.ui.dialogV2.confirm.show = true;
    },
    HIDE_MODAL_DIALOG_CONFIRM_V2(state) {
      state.ui.dialogV2.confirm = {
        show: false,
        title: "",
        maxWidth: "400px",
        message: "",
        content: "",
        yesText: "Yes",
        cancelText: "Cancel",
        hanldeClickYes: null,
      };
    },
    SHOW_MODAL_DIALOG_CONTENT_V2(state, data) {
      state.ui.dialogV2.content = data;
      state.ui.dialogV2.content.show = true;
    },
    HIDE_MODAL_DIALOG_CONTENT_V2(state) {
      state.ui.dialogV2.content = {
        show: false,
        title: "",
        maxWidth: "700px",
        width: "700px",
        content: "",
        actions: null,
      };
    },
    setProjects(state, payload) {
      state.projects = payload;
    },
    setShortSideNav(state, sw) {
      state.shortSideNav = sw;
    },
  },
  actions: {
    /** 프로젝트 리스트를 셋한다 */
    setProjects({ commit }, data) {
      commit("setProjects", data);
    },
    /** side navigation을 토글 한다 */
    setShortSideNav({ commit }, sw) {
      commit("setShortSideNav", sw);
    },
  },
});
