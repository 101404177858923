import ProjectAuthProvider from "@/components/common/ProjectAuthProvider/ProjectAuthProvider";
import ProfileGuard from "@/components/common/ProfileGuard/ProfileGuard";

export default [
  {
    path: "/projectList",
    name: "ProjectList",
    component: () =>
      import("../pages/main/modernframework/Project/ProjectList.vue"),
  },
  /** 프로젝트 관리 리스트 */
  {
    path: "/Setup/projects",
    name: "ProjectConfiguration",
    component: () =>
      import("../pages/main/modernframework/ProjectV2/ProjectListV2/index.vue"),
  },
  /** 프로젝트 관리 생성 */
  {
    path: "/Setup/projects/create",
    name: "ProjectCreate",
    component: () =>
      import(
        "../pages/main/modernframework/ProjectV2/ProjectCreateV2/index.vue"
      ),
  },
  /** 프로젝트 관리 상세 */
  {
    path: "/Setup",
    component: ProjectAuthProvider,
    children: [
      {
        path: "",
        component: ProfileGuard,
        children: [
          {
            path: "projects/:projectId",
            name: "ProjectDetail",
            component: () =>
              import(
                "../pages/main/modernframework/Project/ProjectNewDetail.vue"
              ),
          },
        ],
      },
    ],
  },
];
